import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';

const PublicationTemplate = ({ data }) => {
    const { frontmatter, body } = data.mdx;
    return (
        <Layout>
            <div className="container">
                <h1>{frontmatter.title}</h1>
                <p>{frontmatter.date}</p>
                <MDXRenderer>{body}</MDXRenderer>
            </div>
        </Layout>
    );
};

export default PublicationTemplate;
export const query = graphql`
  query PublicationsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY MMMM Do")
      }
    }
  }
`;